/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
import React, { useState } from "react"
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io"
import feature1ImageOn from "../../../assets/images/technology/dep/feature-1-on.png"
import feature2ImageOn from "../../../assets/images/technology/dep/feature-2-on.png"
import feature3ImageOn from "../../../assets/images/technology/dep/feature-3-on.png"
import feature4ImageOn from "../../../assets/images/technology/dep/feature-4-on.png"
import feature5ImageOn from "../../../assets/images/technology/dep/feature-5-on.png"
import feature6ImageOn from "../../../assets/images/technology/dep/feature-6-on.png"
import feature1ImageOff from "../../../assets/images/technology/dep/feature-1-off.png"
import feature2ImageOff from "../../../assets/images/technology/dep/feature-2-off.png"
import feature3ImageOff from "../../../assets/images/technology/dep/feature-3-off.png"
import feature4ImageOff from "../../../assets/images/technology/dep/feature-4-off.png"
import feature5ImageOff from "../../../assets/images/technology/dep/feature-5-off.png"
import feature6ImageOff from "../../../assets/images/technology/dep/feature-6-off.png"
/* -------------------------------------------------------------------------- */
/*                            Functional Component                            */
/* -------------------------------------------------------------------------- */
export default function Features() {
    /* -------------------------------------------------------------------------- */
    /*                                 Containers                                 */
    /* -------------------------------------------------------------------------- */
    const [featuresToggleObject, setFeaturesToggleObject] = useState({
        smartEnergy: true,
        licensing: false,
        trade: false,
        dep: false,
        insurance: false,
        agent: false,
    })
    /* -------------------------------------------------------------------------- */
    /*                                   Render                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className="dep-features">
            <div className="row col-lg-8 mx-auto">
                <div className="col-lg-4 dep-features-titles-list">
                    <div>
                        <span className="list-number">01</span>
                        <span className="list-title">smart energy</span>
                    </div>
                    <div>
                        <span>02</span>
                        <span>smart agent</span>
                    </div>
                    <div>
                        <span>03</span>
                        <span>trade</span>
                    </div>
                    <div>
                        <span>04</span>
                        <span>open dep</span>
                    </div>
                    <div>
                        <span>05</span>
                        <span>insurance</span>
                    </div>
                    <div>
                        <span>06</span>
                        <span>licensing</span>
                    </div>
                </div>
                <div className="col-lg-8"></div>
            </div>
        </div>
    )
}
