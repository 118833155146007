/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
import React from "react"
import forEveryOneBanner from "../../../assets/images/technology/dep/for-everyone.svg"
/* -------------------------------------------------------------------------- */
/*                            Functional Component                            */
/* -------------------------------------------------------------------------- */
export default function ForEveryone() {
    /* -------------------------------------------------------------------------- */
    /*                                   Render                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className="col-lg-12 p-0 for-everyone">
            <img src={forEveryOneBanner} className="for-everyone-banner" alt="For Everyone" />
            <h1 className="for-everyone-title">For everyone</h1>
            <span className="for-everyone-label for-everyone-label-1 text-dark">governments</span>
            <span className="for-everyone-label for-everyone-label-2 text-dark">companies</span>
            <span className="for-everyone-label for-everyone-label-3 text-dark">insurances</span>
            <span className="for-everyone-label for-everyone-label-4 text-dark">
                independent suppliers
            </span>
            <span className="for-everyone-label for-everyone-label-5 text-dark">individuals</span>
            <span className="for-everyone-label for-everyone-label-6 text-dark">ndb</span>
        </div>
    )
}
