/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
import React, { useState } from "react"
import Container from "../../../components/Container"
import { EnergyDistributionContent } from "../../../utilities/content/dep"
/* -------------------------------------------------------------------------- */
/*                            Functional Component                            */
/* -------------------------------------------------------------------------- */
export default function EnergyDistribution() {
    const [currentEnergyIndicator, setCurrentEnergyIndicator] = useState(0)
    /* -------------------------------------------------------------------------- */
    /*                                   Methods                                  */
    /* -------------------------------------------------------------------------- */
    const activateItem = index => {
        setCurrentEnergyIndicator(index)
    }
    /* -------------------------------------------------------------------------- */
    /*                                   Render                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <Container>
            <div className="py-5 energy-distribution">
                <h1 className="text-center font-weight-bold mb-5">
                    Fair energy distribution and security
                </h1>
                {EnergyDistributionContent?.map((item, index) => {
                    return (
                        <div className="text-center">
                            <img
                                src={item.emptyEnergyImgage}
                                className={`img-fluid pointer energy-image-base ${
                                    currentEnergyIndicator === index ? "opacity-0" : "opacity-1"
                                }`}
                                alt="Empty Energy BluePrint"
                                onClick={() => activateItem(index)}
                            />
                            <img
                                src={item.filledEnergyImage}
                                className={`img-fluid pointer energy-image-overlay ${
                                    currentEnergyIndicator === index ? "opacity-1" : "opacity-0"
                                }`}
                                alt="Energy"
                            />
                            <div
                                className={`${
                                    currentEnergyIndicator === index
                                        ? "open-item-content"
                                        : "close-item-content"
                                }`}
                            >
                                <h2 className="text-center mt-2 text-uppercase">{item.title}</h2>
                                <p className="text-center col-lg-7 mx-auto mt-4">
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </Container>
    )
}
