/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useRef, useState } from "react"
import { EnergyRowContent } from "../../../utilities/content/dep"
/* -------------------------------------------------------------------------- */
/*                            Functional Component                            */
/* -------------------------------------------------------------------------- */
export default function EnergyRow() {
    /* -------------------------------------------------------------------------- */
    /*                                 Containers                                 */
    /* -------------------------------------------------------------------------- */
    // this state might take numbers from 0 to 5 only.
    const [energyRowIndicator, setEnergyRowIndicator] = useState(0)
    const [animation, setAnimation] = useState(true)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const timeout = useRef(null)
    /* -------------------------------------------------------------------------- */
    /*                                   Methods                                  */
    /* -------------------------------------------------------------------------- */
    const stopAnimationAndChangeContent = selectedItemId => {
        setAnimation(false)
        setEnergyRowIndicator(selectedItemId)
        clearTimeout(timeout.current)
    }
    useEffect(() => {
        setTitle(EnergyRowContent[energyRowIndicator].title)
        setDescription(EnergyRowContent[energyRowIndicator].description)
        if (animation)
            timeout.current = setTimeout(() => {
                if (energyRowIndicator < 5) return setEnergyRowIndicator(energyRowIndicator + 1)
                return setEnergyRowIndicator(0)
            }, 3000)
    }, [energyRowIndicator])
    /* -------------------------------------------------------------------------- */
    /*                                   Render                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <>
            <div className="row col-lg-8 mx-auto energy-row">
                {EnergyRowContent?.map(item => {
                    return (
                        <div className="col-lg-2 p-0 text-center">
                            <img
                                src={item.emptyEnergyImgage}
                                className={`pointer inactive-item ${
                                    item.id === energyRowIndicator ? "d-none" : "d-block"
                                }`}
                                onClick={() => stopAnimationAndChangeContent(item.id)}
                                alt="Energy"
                            />
                            <img
                                src={item.filledEnergyImage}
                                className={`pointer active-item ${
                                    item.id === energyRowIndicator ? "d-block" : "d-none"
                                }`}
                                alt="Energy"
                            />
                        </div>
                    )
                })}
            </div>
            <div className="row col-lg-8 mx-auto energy-row mb-5 pb-5">
                <h2 className="text-center col-12 font-weight-bold text-dark mt-4">{title}</h2>
                <h3 className="text-center col-7 font-weight-normal mt-4 mx-auto lh-sm energy-row-description">
                    {description}
                </h3>
            </div>
        </>
    )
}
