import React from "react"
import Container from "../../Container"

export default function DepTitle() {
    return (
        <div className="dep-title">
            <Container>
                <br />
                <br />
                <h1>DEP</h1>
                <h3 className="text-center">DECENTRALIZED ENERGY PLATFORM</h3>
            </Container>
        </div>
    )
}
