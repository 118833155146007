import filledEnergyImage from "../../assets/images/technology/dep/energy.png"
import emptyEnergyImage0 from "../../assets/images/technology/dep/empty-energy-0.svg"
import emptyEnergyImage1 from "../../assets/images/technology/dep/empty-energy-1.svg"
import emptyEnergyImage2 from "../../assets/images/technology/dep/empty-energy-2.svg"
import emptyEnergyImage3 from "../../assets/images/technology/dep/empty-energy-3.svg"
import emptyEnergyImage4 from "../../assets/images/technology/dep/empty-energy-4.svg"
import emptyEnergyImage5 from "../../assets/images/technology/dep/empty-energy-5.svg"

export const EnergyRowContent = [
    {
        id: 0,
        title: "Fast and real-time settlements",
        description: "Ensuring that VOLT is only transferred if the energy traded is delivered.",
        emptyEnergyImgage: emptyEnergyImage0,
        filledEnergyImage: filledEnergyImage,
    },
    {
        id: 1,
        title: "Energy tracking",
        description:
            "Both utility providers and consumers can monitor their supply and consumption using the VOLT",
        emptyEnergyImgage: emptyEnergyImage1,
        filledEnergyImage: filledEnergyImage,
    },
    {
        id: 2,
        title: "Automated and efficient billing systems",
        description: "Accurate billing between traders and remove transaction intermediary costs",
        emptyEnergyImgage: emptyEnergyImage2,
        filledEnergyImage: filledEnergyImage,
    },
    {
        id: 3,
        title: "Smart contracts",
        description:
            "Supply and demand are closely matched through automatic trades, thus improving reliability",
        emptyEnergyImgage: emptyEnergyImage3,
        filledEnergyImage: filledEnergyImage,
    },
    {
        id: 4,
        title: "Privacy and security",
        description:
            "facilitates the verification of shared information through cryptography, digital signatures while having high resiliency to attacks and forgery due to its tamper-proof nature.",
        emptyEnergyImgage: emptyEnergyImage4,
        filledEnergyImage: filledEnergyImage,
    },
    {
        id: 5,
        title: "Full transparency",
        description: "Consumers can choose their suppliers according to their preferences",
        emptyEnergyImgage: emptyEnergyImage5,
        filledEnergyImage: filledEnergyImage,
    },
]

export const EnergyDistributionContent = [
    {
        id: 0,
        title: "One platform unlimited power",
        description:
            "Using DEP enables energy consumption as close to its use as possible while giving access to the global energy network.",
        emptyEnergyImgage: emptyEnergyImage0,
        filledEnergyImage: filledEnergyImage,
    },
    {
        id: 1,
        title: "Fusion of the best",
        description:
            "A possibility for utility providers, alongside other peers, to play a role in globally fair and transparent energy distribution.",
        emptyEnergyImgage: emptyEnergyImage1,
        filledEnergyImage: filledEnergyImage,
    },
    {
        id: 2,
        title: "Eco-friendly Market",
        description:
            "An ecosystem to generate grid or off-grid energy and deliver economic and practical benefits to consumers and producers.",
        emptyEnergyImgage: emptyEnergyImage2,
        filledEnergyImage: filledEnergyImage,
    },
]

export const CircleSectionContent = [
    {
        id: 1,
        title: "tax and network usage tariffs",
        description:
            "Maintain market players and their eligibility for taxing and network usage tariffs",
    },
    {
        id: 2,
        title: "plug-and-play <br /> technology",
        description: "Smart meters to differentiate between energy traded from/to the grid",
    },
    {
        id: 3,
        title: "clean energy",
        description:
            "Incentives form the ecosystem for promoting clean energy alongside eco-friendly and conscious consumers serving their load demand from clean energy sources",
    },
    {
        id: 4,
        title: "fair competition",
        description:
            "Trade while guaranteeing the privacy and security of identities and market bids",
    },
    {
        id: 5,
        title: "blockchain security",
        description:
            "The market results derived from the decentralized market-clearing method are stored in a secured manner",
    },
    {
        id: 6,
        title: "reduce carbon emission",
        description:
            "Reducing the societal carbon emission level by incentivizing investments in distributed energy resources",
    },
    {
        id: 7,
        title: "improve market liquidity",
        description:
            "Improve market liquidity and welfare by eliminating intermediary entities and their related costs",
    },
    {
        id: 8,
        title: "transparency",
        description: "Give consumers the freedom to choose the type of energy they can purchase",
    },
    {
        id: 9,
        title: "incentive market",
        description:
            "Incentivize market players to engage in trading activity and create market competition",
    },
    {
        id: 10,
        title: "shared economy",
        description:
            "Cultivate trust and collaboration as resources are shared within the ecosystem",
    },
]
